import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';

import QRCodeBTC from './images/1.jpg';
import QRCodeETH from './images/2.jpg';
import QRCodeBNB from './images/3.jpg';
//import QRCodeUSDT from './images/qrcode_usdt.jpg';

const API_URL = process.env.REACT_APP_API_URL;

function App() {
  let { id, amount, slug } = useParams();
  const [username, setUsername] = useState("");
  const [currency, setCurrency] = useState(slug);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [qrCode, setQRCode] = useState("");
  const [transactions, setTransactions] = useState([]);

  const copyFn = () => {
    var copyText = document.getElementById("address-field");
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices
    navigator.clipboard.writeText(copyText.value);
    Swal.fire("Copied", "Wallet address copied", "success");
  };

  const confirmPayment = () => {
    const form = document.getElementById('image-form');
    const imageInput = document.getElementById('file');
    setLoading(true);

    const formData = new FormData();
    formData.append('image', imageInput.files[0]);

    axios.post(`${API_URL}/confirm-payment.php`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => {
      Swal.fire('Under Review', 'We are confirming your deposit', 'warning')
        .then(() => {
          handleBack();
        });
      setLoading(false);
    })
    .catch((error) => {
      console.error('Error uploading image:', error);
      setLoading(false);
    });
  };

  useEffect(() => {
    axios.get(`${API_URL}/get-user.php?id=${id}`)
      .then((res) => {
        const user = res.data;
        setUsername(user.username);
      });

    let address;
    let qrCode;
    switch (currency) {
      case "BTC":
        address = "bc1q883pwgyhzhdkjyr90wuclv8acyuu576ew2p7lf";
        qrCode = QRCodeBTC;
        break;
      case "ETH":
        address = "0xB0455D588D95f5e96F20053C187dfA86A50deada";
        qrCode = QRCodeETH;
        break;
      case "USDT":
        address = "TNqy8NdZw1xnWcJNNVFWiNwrC6aDHcVTmj";
        qrCode = QRCodeBNB;
        break;
      // Add more cases for other currencies as needed
      default:
        address = "Invalid Currency";
        qrCode = null;
        break;
    }
    setAddress(address);
    setQRCode(qrCode);

    axios.get(`${API_URL}/get-transactions.php?id=${id}`)
      .then((res) => {
        setTransactions(res.data);
      });
  }, [id, currency]);

  const handleBack = () => {
    window.history.back();
  };

  return (
    <>
      <div className="welcome-bar">
        <div className="flex-within-welcome">
          <button onClick={handleBack} className="user-icon"><i className='fa fa-arrow-left'></i></button>
          <p>Welcome, {username}</p>
        </div>
      </div>

      <div className="after-container">
        <div className="left-side">
          <div className='header-flex'>
            <h1>INVOICE PAYMENT DETAILS</h1>
            {!loading && (
              <button className='confirm-pay-btn' id='confirm-btn' onClick={() => { document.getElementById("file").click() }}>
                confirm payment
              </button>
            ) || (
              <button className='confirm-pay-btn' id='confirm-btn'>Confirming ...</button>
            )}
            <form method="post" encType='multipart/form-data' style={{ display: "inline" }} id='image-form'>
              <input type='file' name="file" id='file' accept="image/*" onChange={confirmPayment} hidden />
            </form>
          </div>

          <p>Pay to:</p>
          <input className="wallet-ad-container px-2" value={address} id="address-field" readOnly />
          <button className="copy-button" onClick={copyFn}><FontAwesomeIcon icon={faCopy} /> Copy</button>

          <hr className='hr'></hr>
          <p>NETWORK: {currency}</p>

          <hr className='hr'></hr>

          <p>QR CODE:</p>
          {qrCode ? <img src={qrCode} alt="QR Code" className='qrcode' /> : <p>Invalid Currency</p>}
          <p className='p'>If the QR code doesn't work with your wallet, simply copy and paste the <br /> address displayed above.</p>

          <hr className='hr'></hr>

          <p>Invoice Amount:</p>
          <p className='p-bold'>${amount} ON {currency}</p>

          <hr className='hr'></hr>

          <p>Amount Due:</p>
          <p className='p-bold'>${amount} ON {currency}</p>

          <hr className='hr'></hr>
        </div>

        <div className="right-side">
          <h1>PAYMENT(S) HISTORY</h1>
          <p className='p'>Real-time historical records of your incoming invoice payments</p>
          <div className='overflow-cont'>
            <div className='topic-cont'>
              <div className='topics'>TRANSACTION ID</div>
              <div className='topics'>AMOUNT</div>
              <div className='topics'>STATUS</div>
              <div className='topics'>DATE</div>
            </div>

            {transactions.length > 0 && (
              transactions.map((trx) => (
                <div key={trx.trx_id} className='transact-cont'>
                  <div className='transact'>#{trx.trx_id}</div>
                  <div className='transact'>${trx.amount}</div>
                  <div className='transact'><div className='status'>{trx.status}</div></div>
                  <div className='transact'>{trx.date}</div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
